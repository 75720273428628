import React, {Component, createContext, useState} from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLongArrowAltRight,
  faGlobeAsia,
  faIndustry,
  faDollarSign,
  faUsersSlash,
  faHandshake,
  faBoxes,
  faTruckLoading,
  faPhone,
  faLocationArrow,
  faEnvelopeOpenText,
  faHeadset,
  faFileSignature,
  faShippingFast,
  faAddressBook,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons"

import {
  faFacebookF,
  faInstagram,
  faTelegramPlane,
} from "@fortawesome/free-brands-svg-icons"

import "../pages/mystyles.scss"

const ShoppingCart = createContext()

export const ShoppingConsumer = ShoppingCart.Consumer

library.add(
  fab,
  faGlobeAsia,
  faIndustry,
  faDollarSign,
  faUsersSlash,
  faHandshake,
  faBoxes,
  faTruckLoading,
  faPhone,
  faLocationArrow,
  faEnvelopeOpenText,
  faFacebookF,
  faInstagram,
  faLongArrowAltRight,
  faHeadset,
  faFileSignature,
  faShippingFast,
  faAddressBook,
  faTelegramPlane,
  faShoppingBasket
)

const Layout = ({children}) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      phone: allSanityPhone {
        edges {
          node {
            phone
          }
        }
      }
      social: allSanitySocial {
        edges {
          node {
            name
            social
          }
        }
      }
    }
  `)

  const [shoppingStatus, setShoppingStatus] = useState(false);
  const [lang, setLang] = useState('ru');
  const [cart, setCart] = useState([]);

  const handleLang = (lg) => {
    setLang(lg);
    navigate(`/${lg}`);
  }

  const handleAddToCart = (data) => {
    setCart(prevState => [...prevState, data])
  }

  const handleRemoveCart = (data) => {
    setCart(cart.filter(function(cart) {
      return cart !== data
    }));
  }

  const handleButton = () => {
    setCart([]);
  }

  const handleSuccess = () => {
    navigate("/")
  }

  return (
      <ShoppingCart.Provider value={{
        shoppingStatus: shoppingStatus,
        lang: lang,
        cart: cart,
        handleAddToCart: handleAddToCart,
        handleRemoveCart: handleRemoveCart,
        handleButton: handleButton,
        handleSuccess: handleSuccess
      }}>
        <nav
            className="navbar is-light has-family-inter is-size-7"
            role="navigation"
            aria-label="main navigation"
        >
          <div className="container">
            <div className="navbar-menu is-active">
              <div className="navbar-start has-text-weight-bold">
                <div style={{cursor: 'pointer', color: 'red'}} onClick={() => handleLang('ru')} className="navbar-item">
                  RU
                </div>
                <div style={{cursor: 'pointer', color: 'red'}} onClick={() => handleLang('en')} className="navbar-item">
                  EN
                </div>
              </div>
              <div className="navbar-end has-text-weight-bold">
                {data.phone.edges.map((item, i) => (
                    <a key={i} href={`tel:${item.node.phone}`} className="navbar-item is-size-7">
                      {item.node.phone}
                    </a>
                ))}


                {data.social.edges.map((item, i) => (
                    <>
                      {(item.node.name !== 'E-mail') && <a  key={i} href={item.node.social} target="_blank" className="navbar-item is-flex align-items-center">
                        {item.node.name}
                      </a>}
                    </>
                ))}

              </div>
            </div>
          </div>
        </nav>
        <main>{children}</main>
        <footer className="footer">
          <div className="container has-text-centered has-text-hex-5f6368">
            <p className="is-size-7">
              © {new Date().getFullYear()} Agro Export Alyans.{" "}
              Все права защищены.
            </p>
            <a href="https://jahondev.uz/" target="_blank" className="is-size-7">
              Сайт разработан www.jahondev.uz
            </a>
          </div>
        </footer>
      </ShoppingCart.Provider>
  )
}

export default Layout
